import ServiceApi from "@/services/ServiceApi";

/** Сервис для получения инфы о конструкции */
export default class ServiceConstruction {

    static _microserviceName = 'free';

    static _requestRouts = {
        _getInfo: '/catalog-info/'
    };

    /**
     * Получить инфо о конструкции
     * @param {String} oohdeskId
     * @param {Function} actionAfter
     * @returns {Promise<void>}
     * @private
     */
    static async getInfoByOohdeskId(oohdeskId, actionAfter = () => {}) {
        try {
            const response = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts._getInfo + oohdeskId
            );

            const {data: responseData = {} } = response;
            const {data = {}} = responseData;
            actionAfter(data);
        } catch (error) {
            console.log(error);
        }
    }

}
